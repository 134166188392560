.default {
  background-color: var(--color-secondary);
  border: solid 1px var(--color-primary);
  border-radius: 0.25rem;
  color: var(--color-primary);
  padding: 0.25rem 0.8rem;
  cursor: pointer;
}

.default:hover {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}
